import React, { useState } from "react";
import { toast } from "react-toastify";
import newApi from "service/newApi";

const AudioPlayer = ({ filename, source, audio }) => {
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(audio);
  const [error, setError] = useState(null);

  const fetchAudioUrl = async () => {
    setLoading(true);
    setError(null);
    setAudioUrl(null);

    newApi
      .post("/v1/attendances/audio", {
        filename,
        source,
      })
      .then((response) => {
        setAudioUrl(response.data.audio);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error("Áudio não disponível.");
          setError("Áudio não disponível.");
          setLoading(false);
          return;
        }

        toast.error("Áudio do atendimento não detectado.");
        setError("Áudio do atendimento não detectado.");
        setLoading(false);
      });
  };

  return (
    <div>
      {!audioUrl && (
        <button
          onClick={fetchAudioUrl}
          disabled={loading}
          style={{
            padding: "10px 60px",
            fontSize: "14px",
            borderRadius: "4px",
            border: "none",
          }}
        >
          <b>Carregar Áudio</b>
        </button>
      )}
      {loading && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <div className="spinner" />
          <p>Recuperando áudio do atendimento, por favor aguarde...</p>
        </div>
      )}
      {error && (
        <p style={{ color: "red", marginTop: "10px" }}>
          <b>{error}</b>
        </p>
      )}
      {audioUrl && (
        <audio controls style={{ marginTop: "20px" }}>
          <source src={audioUrl} type="audio/wav" />
          Este tipo de áudio não é suportado.
        </audio>
      )}
    </div>
  );
};

export default AudioPlayer;

const spinnerStyle = document.createElement("style");
spinnerStyle.innerHTML = `
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
document.head.appendChild(spinnerStyle);
