import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Alert,
} from "reactstrap";
// core components
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import ModalAttendanceAnalysisPrompt from "components/Modal/ModalAttendanceAnalysisPrompt";
import ModalAttendanceAnalysisResponse from "components/Modal/ModalAttendanceAnalysisResponse";
import ModalAttendanceAnalysisFeedback from "components/Modal/ModalAttendanceAnalysisFeedback";
import Loading from "components/Loading";
import newApi from "service/newApi";
import AudioPlayer from "components/AudioPlayer";

const Attendance = () => {
  const { id: attendanceId } = useParams();
  const [attendance, setAttendance] = useState({});
  const [attendanceName, setAttendanceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [score, setScore] = useState(null);
  const [updateAttendance, setUpdateAttendance] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderPromptsBy, setOrderPromptsBy] = useState([]);

  const questionRegex = /<pergunta>(.*?)<pergunta>/;

  useEffect(() => {
    setLoading(true);

    newApi
      .get(`/v1/attendances/${attendanceId}`)
      .then((response) => response.data)
      .then((response) => {
        setAttendance(response ?? {});
        setAttendanceName(response?.attendance ?? "");
        setScore(response?.consultantScore ?? null);
        setMessages(response.messages ?? []);
        setPrompts(response.prompts ?? []);
        setTotalPrice(
          response.prompts.reduce((acc, curr) => {
            return acc + curr.price;
          }, 0),
        );
        setLoading(false);
      })
      .catch(() => {
        toast.error("Erro ao recuperar as análises do atendimento!");
        setLoading(false);
        return;
      });
  }, [attendanceId]);

  useEffect(() => {
    if (updateAttendance) {
      setLoading(true);
      newApi
        .get(`/v1/attendances/${attendanceId}`)
        .then((response) => response.data)
        .then((response) => {
          setAttendance(response ?? {});
          setScore(response?.consultantScore ?? null);
          setMessages(response.messages ?? []);
          setPrompts(response.prompts ?? []);
          setLoading(false);
          setTotalPrice(
            response.prompts.reduce((acc, curr) => {
              return acc + curr.price;
            }, 0),
          );
          setUpdateAttendance(false);
        })
        .catch(() => {
          toast.error("Erro ao recuperar as análises do atendimento!");
          setLoading(false);
          setUpdateAttendance(false);
          return;
        });
    }
  }, [updateAttendance]);

  // const deleteAnalysis = useCallback((id) => {
  //   api
  //     .delete(`/attendances/analysis/${id}`)
  //     .then((response) => {
  //       toast.success("Analise deletada com sucesso!");
  //       setUpdateAttendance(true);
  //     })
  //     .catch((error) => {
  //       toast.error("Erro ao deletar analise!");
  //     });
  // }, []);

  return (
    <>
      {/* Page content */}
      <Container className="mt-7" fluid>
        {/* <Row> */}
        <Col className="" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Atendimento: {attendanceName}</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <Alert color="primary">
                    Foram processados{" "}
                    <b>
                      {prompts?.filter((p) => p.type === "AI").length ?? 0}{" "}
                      prompt(s)
                    </b>{" "}
                    para o atendimento com o valor total de{" "}
                    <b>${totalPrice ?? 0} USD</b>
                  </Alert>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label">Audio</label>
                    <div>
                      {!loading && (
                        <AudioPlayer
                          filename={attendanceName}
                          source={attendance?.source}
                          audio={attendance?.audio}
                        />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label">
                      Informações do atendimento
                    </label>
                    <Col className="pl-lg-12">
                      <div>
                        <label className="form-control-label">
                          Segmento:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance?.segment?.title ?? "N/A"}
                          </span>
                        </label>
                        <br />
                        <label className="form-control-label">
                          Processado por:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance.user?.username ?? "N/A"}
                          </span>
                        </label>
                        <br />

                        <label className="form-control-label">
                          Supervisor:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance.supervisorExternalId ?? "N/A"}
                          </span>
                        </label>
                        <br />

                        <label className="form-control-label">
                          Consultor:{" "}
                          <span
                            style={{
                              fontWeight: 300,
                            }}
                          >
                            {attendance.consultantExternalId ?? "N/A"}
                          </span>
                        </label>
                      </div>
                    </Col>
                  </FormGroup>
                </Col>
              </div>
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <label className="form-control-label">Transcrição</label>
                    <div
                      className="styled-scroll"
                      style={{
                        width: "100%",
                        height: "400px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {loading ? <Loading /> : ""}
                      <Table
                        style={{
                          tableLayout: "fixed",
                          width: "100%",
                        }}
                      >
                        <thead className="thead-ligth">
                          <tr>
                            <th scope="col">Mensagens</th>
                          </tr>
                        </thead>
                        <tbody>
                          {messages.map((message, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <div
                                    style={{
                                      width: "100%",
                                      whiteSpace: "pre-line",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {message}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </FormGroup>
                </Col>
              </div>
              <br />
              <div className="pl-lg-12">
                <Col className="pl-lg-12">
                  <FormGroup>
                    <Row
                      style={{
                        margin: "8px 0",
                      }}
                    >
                      <Col
                        className="text-center d-flex align-items-center justify-content-flex-start"
                        md={1}
                        style={{
                          padding: 0,
                        }}
                      >
                        <label
                          className="form-control-label"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          Análises IA
                        </label>
                      </Col>
                      <br />
                    </Row>
                    <div
                      className="styled-scroll"
                      style={{
                        width: "100%",
                        height: "400px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {loading ? <Loading /> : ""}
                      <Table
                        style={{
                          tableLayout: "fixed",
                          width: "100%",
                        }}
                      >
                        <thead className="thead-ligth">
                          <tr className="custom-head">
                            <th
                              scope="col"
                              onClick={() =>
                                setOrderPromptsBy([
                                  "type",
                                  orderPromptsBy[1] === "desc" ? "asc" : "desc",
                                ])
                              }
                              style={{ width: "25px" }}
                            >
                              Tipo{" "}
                              {orderPromptsBy.length &&
                              orderPromptsBy[0] === "type"
                                ? orderPromptsBy[1] === "desc"
                                  ? "🠋"
                                  : "🠉"
                                : ""}
                            </th>
                            <th
                              scope="col"
                              onClick={() =>
                                setOrderPromptsBy([
                                  "prompt",
                                  orderPromptsBy[1] === "desc" ? "asc" : "desc",
                                ])
                              }
                            >
                              Prompt{" "}
                              {orderPromptsBy.length &&
                              orderPromptsBy[0] === "prompt"
                                ? orderPromptsBy[1] === "desc"
                                  ? "🠋"
                                  : "🠉"
                                : ""}
                            </th>
                            <th
                              scope="col"
                              onClick={() =>
                                setOrderPromptsBy([
                                  "response",
                                  orderPromptsBy[1] === "desc" ? "asc" : "desc",
                                ])
                              }
                            >
                              Resposta{" "}
                              {orderPromptsBy.length &&
                              orderPromptsBy[0] === "response"
                                ? orderPromptsBy[1] === "desc"
                                  ? "🠋"
                                  : "🠉"
                                : ""}
                            </th>
                            <th
                              scope="col"
                              onClick={() =>
                                setOrderPromptsBy([
                                  "checklist",
                                  orderPromptsBy[1] === "desc" ? "asc" : "desc",
                                ])
                              }
                            >
                              Checklist{" "}
                              {orderPromptsBy.length &&
                              orderPromptsBy[0] === "checklist"
                                ? orderPromptsBy[1] === "desc"
                                  ? "🠋"
                                  : "🠉"
                                : ""}
                            </th>
                            <th scope="col">Feedback / Ações</th>
                            <th
                              scope="col"
                              onClick={() =>
                                setOrderPromptsBy([
                                  "price",
                                  orderPromptsBy[1] === "desc" ? "asc" : "desc",
                                ])
                              }
                            >
                              Preço ( USD ){" "}
                              {orderPromptsBy.length &&
                              orderPromptsBy[0] === "price"
                                ? orderPromptsBy[1] === "desc"
                                  ? "🠋"
                                  : "🠉"
                                : ""}
                            </th>
                            <th
                              scope="col"
                              onClick={() =>
                                setOrderPromptsBy([
                                  "date",
                                  orderPromptsBy[1] === "desc" ? "asc" : "desc",
                                ])
                              }
                            >
                              Horário de análise{" "}
                              {orderPromptsBy.length &&
                              orderPromptsBy[0] === "date"
                                ? orderPromptsBy[1] === "desc"
                                  ? "🠋"
                                  : "🠉"
                                : ""}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {prompts
                            .filter((prompt) => prompt.type !== "HUMAN")
                            .sort((a, b) => {
                              if (orderPromptsBy.length) {
                                let firstData = a;
                                let secondData = b;

                                switch (orderPromptsBy[0]) {
                                  case "type":
                                    firstData = a.type;
                                    secondData = b.type;
                                    break;
                                  case "prompt":
                                    firstData = a.prompt;
                                    secondData = b.prompt;
                                    break;
                                  case "response":
                                    firstData = a.response;
                                    secondData = b.response;
                                    break;
                                  case "checklist":
                                    firstData = a.checklist;
                                    secondData = b.checklist;
                                    break;
                                  case "price":
                                    firstData = (a.price * 10000).toString();
                                    secondData = (b.price * 10000).toString();
                                    break;
                                }

                                if (orderPromptsBy[0] === "date") {
                                  firstData = new Date(a.createdAt);
                                  secondData = new Date(b.createdAt);
                                  if (orderPromptsBy[1] === "desc") {
                                    return firstData - secondData;
                                  }
                                  return secondData - firstData;
                                }

                                const questionA = firstData?.replace(
                                  questionRegex,
                                  "$1",
                                );

                                const questionB = secondData?.replace(
                                  questionRegex,
                                  "$1",
                                );

                                let numA = questionA.match(/\d+/g);
                                let numB = questionB.match(/\d+/g);

                                numA = numA ? parseInt(numA[0], 10) : 0;
                                numB = numB ? parseInt(numB[0], 10) : 0;

                                if (orderPromptsBy[1] === "desc") {
                                  if (numA === numB) {
                                    return questionA.localeCompare(questionB);
                                  } else {
                                    return numA - numB;
                                  }
                                }
                                if (numA === numB) {
                                  return questionB.localeCompare(questionA);
                                } else {
                                  return numB - numA;
                                }
                              }

                              return true;
                            })
                            .map((prompt, i) => {
                              return (
                                <tr key={i}>
                                  <td>{prompt.type}</td>
                                  <td
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <ModalAttendanceAnalysisPrompt
                                      id={i}
                                      question={prompt.prompt}
                                      prompt={prompt.prompt}
                                    />
                                  </td>
                                  <td
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <ModalAttendanceAnalysisResponse
                                      response={prompt.response}
                                    />
                                  </td>
                                  <td>{prompt.checklist}</td>
                                  <td>
                                    <ModalAttendanceAnalysisFeedback
                                      attendance={prompt.attendance}
                                      analysisId={prompt.id}
                                      promptId={prompt.promptId}
                                    />
                                  </td>
                                  <td>${prompt.price}</td>
                                  <td>
                                    {new Date(prompt.createdAt).toLocaleString(
                                      "pt-BR",
                                      {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      },
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </FormGroup>
                </Col>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* </Row> */}
      </Container>
    </>
  );
};

export default Attendance;
